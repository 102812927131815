import { ModalWrapper } from '@ui/components';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './onboarding.styles.css';
import { Carousel } from 'react-responsive-carousel';
import { OnboardingItem } from './onboarding-item';

import badgesImage from '@assets/onboarding-badges.png';
import leaderboardImage from '@assets/onboarding-leaderboard.png';
import messagesImage from '@assets/onboarding-message.png';
import exploreImage from '@assets/onboarding-explore.png';
import builderImage from '@assets/onboarding-builder.png';
import { useEffect, useState } from 'react';
import { useUpdateUserMutation } from '@lib/redux/api/user-api';

interface IOnboarding {
  isOnboarded: boolean;
}

const ONBOARDING_STEPS = [
  {
    title: 'New Era of Connecting',
    description:
      'Thanks for being one of the first to collect a badge on KOR! All current badges are of the Builders Series; providing future exclusive perks.',
    image: badgesImage.src,
  },
  {
    title: 'Compete and Climb the Leaderboard!',
    description:
      'Earn badges to compete for the top spots and showcase your exclusive badge collection.',
    image: leaderboardImage.src,
  },
  {
    title: 'Get Messages',
    description:
      'Receive personalized messages from your favorite creators. Keep a lookout for special notes just for you!',
    image: messagesImage.src,
  },
  {
    title: 'Thank you for Collecting!',
    description:
      'By owning the builders series of badges you are invited to come along for the ride, we may call on you for input in shaping the future! - The KOR Team',
    image: builderImage.src,
  },
  {
    title: 'Now Go And Explore KOR!',
    description: 'Customize your profile by changing your user name and profile picture',
    image: exploreImage.src,
  },
];

export const ONBOARDING_STEPS_AMOUNT = ONBOARDING_STEPS.length;

export function Onboarding({ isOnboarded }: IOnboarding) {
  const [page, setPage] = useState(0);
  const [isOnboardingOpen, setOnboardingOpen] = useState(isOnboarded);

  useEffect(() => {
    setOnboardingOpen(isOnboarded);
  }, [isOnboarded]);

  const [updateUserRequest] = useUpdateUserMutation();

  function onNext() {
    setPage((prevPage) => {
      if (page < ONBOARDING_STEPS_AMOUNT) {
        return prevPage + 1;
      }
      return prevPage;
    });
  }

  function onSkip() {
    setOnboardingOpen(false);
    updateUserRequest({
      onboarded: true,
    });
  }

  function onSwipeEnd(newPage: number) {
    if (page !== newPage) {
      setPage(newPage);
    }
  }

  return (
    <ModalWrapper isOpen={isOnboardingOpen} type="center" className="bottom-0 left-0 right-0 top-0">
      <Carousel
        className="flex w-full lg:max-w-lg lg:rounded-2xl"
        onChange={onSwipeEnd}
        autoPlay={false}
        showStatus={false}
        showThumbs={false}
        showArrows={false}
        showIndicators={false}
        infiniteLoop={false}
        emulateTouch={true}
        selectedItem={page}>
        {ONBOARDING_STEPS.map((item, index) => (
          <OnboardingItem key={index} {...item} index={index} onNext={onNext} onSkip={onSkip} />
        ))}
      </Carousel>
    </ModalWrapper>
  );
}
