import { TicketIcon } from './icon-components/ticket';
import { StarIcon } from './icon-components/star';
import { SettingsIcon } from './icon-components/settings';
import { PeopleIcon } from './icon-components/people';
import { BioIcon } from './icon-components/bio';
import { SocialsIcon } from './icon-components/socials';
import { ChatIcon } from '@ui/components/atoms/icon/icon-assets';

const componentsMap = {
  ticket: TicketIcon,
  star: StarIcon,
  settigns: SettingsIcon,
  chat: ChatIcon,
  people: PeopleIcon,
  bio: BioIcon,
  socials: SocialsIcon,
};

type UIIconType = keyof typeof componentsMap;

interface IIcon extends React.SVGProps<SVGSVGElement> {
  type: UIIconType;
}

export function FanIcon({ type: type, ...props }: IIcon) {
  if (!componentsMap[type]) {
    console.error(type + ' icon not found in ui');
    return null;
  }
  const Component = componentsMap[type];
  return <Component {...props} />;
}
