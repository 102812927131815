import { twMerge } from 'tailwind-merge';
import { LoadingSkeleton } from '@ui/components';

interface ICreatorItemLoader {
  className?: string;
}

export function CreatorItemLoader({ className }: ICreatorItemLoader) {
  return (
    <LoadingSkeleton
      className={twMerge(
        'flex h-[440px] w-full max-w-md flex-col items-center rounded-xl',
        className,
      )}>
      <div className="flex flex-col items-center px-6 lg:px-10">
        <LoadingSkeleton className="-mt-8 mb-8 aspect-square w-48 rounded-full" />
        <LoadingSkeleton className="mb-8 h-16 w-3/4 lg:h-24" />
        <div className="mb-12 flex justify-center gap-4 lg:gap-6">
          <div className="flex gap-1">
            <LoadingSkeleton className="aspect-square w-12 rounded-full lg:w-16" />
            <LoadingSkeleton className="h-12 w-20 lg:h-16 lg:w-24" />
          </div>
          <div className="flex gap-1">
            <LoadingSkeleton className="aspect-square w-12 rounded-full lg:w-16" />
            <LoadingSkeleton className="h-12 w-20 lg:h-16 lg:w-24" />
          </div>
        </div>
      </div>
    </LoadingSkeleton>
  );
}
