export const SocialsIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} fill="none" {...props}>
    <g stroke="#707B8F" strokeLinecap="round" strokeWidth={1.5} clipPath="url(#a)">
      <path strokeLinejoin="round" d="M9.75 8.25 16.5 1.5m0 0h-4.008m4.008 0v4.008" />
      <path d="M16.5 9c0 3.536 0 5.303-1.098 6.402C14.303 16.5 12.536 16.5 9 16.5s-5.303 0-6.402-1.098C1.5 14.303 1.5 12.536 1.5 9s0-5.303 1.098-6.402C3.697 1.5 5.464 1.5 9 1.5" />
    </g>
    <defs>
      <clipPath id="a">
        <rect width={18} height={18} fill="#fff" rx={5} />
      </clipPath>
    </defs>
  </svg>
);
