import { Button, Icon, Text, Title } from '@ui/components';
import { ONBOARDING_STEPS_AMOUNT } from './onboarding';
import { twMerge } from 'tailwind-merge';

interface IOnboardingItem {
  title: string;
  description: string;
  image: string;
  index: number;
  onNext(): void;
  onSkip(): void;
}

export function OnboardingItem({
  image,
  title,
  description,
  index,
  onNext,
  onSkip,
}: IOnboardingItem) {
  const isLast = index + 1 === ONBOARDING_STEPS_AMOUNT;
  return (
    <div
      className="flex flex-1 flex-grow flex-col lg:rounded-2xl"
      style={{ background: 'linear-gradient(118deg, #F2E0FF 0%, #FFF4E0 122.59%)' }}>
      <div className="flex h-2/5 items-center justify-center px-8 pt-8 lg:h-auto lg:py-20">
        <img src={image} className="object-cover" />
      </div>
      <div className="flex h-2/5 flex-col px-8 lg:h-full lg:w-full lg:items-center lg:rounded-b-2xl lg:bg-white lg:pt-8">
        <div className="lg:my-auto lg:max-w-sm">
          <Title type="m" className="mb-4 text-left lg:mb-2 lg:text-center">
            {title}
          </Title>
          <Text type="m" className="text-left lg:text-center">
            {description}
          </Text>
        </div>

        <div className="flex gap-2 py-10">
          {Array.from(Array(ONBOARDING_STEPS_AMOUNT).keys()).map((item) => (
            <div
              key={item}
              className={twMerge(
                'h-2 w-2 rounded-full',
                item === index ? 'bg-primary' : 'bg-border',
              )}
            />
          ))}
        </div>

        <div
          className={twMerge(
            'mt-auto flex w-full justify-between pb-8',
            isLast ? 'justify-end' : '',
          )}>
          <Button
            type="secondary"
            className={twMerge('w-32 min-w-0 lg:w-24', !isLast ? 'flex' : 'hidden')}
            onClick={onSkip}>
            Skip
          </Button>
          <Button
            type="primary"
            className={twMerge('flex  min-w-0 gap-2', isLast ? 'w-auto' : 'w-32 lg:w-24')}
            onClick={isLast ? onSkip : onNext}>
            {isLast ? 'Start Expoloring' : 'Next'}
            <Icon type="back" stroke="#fff" className="rotate-180 lg:hidden" />
          </Button>
        </div>
      </div>
    </div>
  );
}
