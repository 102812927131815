import { Note, Text } from '@ui/components';
import { FanIcon } from './fan-icon';

interface ICreatorAchievement {
  type: 'badges' | 'fans';
  value: number | string;
}

export function FanStats({ type, value }: ICreatorAchievement) {
  return (
    <div className="flex flex-grow-0 gap-2">
      <div className="bg-tabActive flex h-12 w-12 items-center justify-center rounded-full">
        <FanIcon type={type === 'badges' ? 'star' : 'people'} />
      </div>
      <div className="flex w-20 flex-col">
        <Text type="m" className="font-extrabold">
          {value || '?'}
        </Text>
        <Note type="s">{type === 'badges' ? 'Collected' : 'Fans'}</Note>
      </div>
    </div>
  );
}
