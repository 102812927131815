export const BioIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={12} height={16} fill="none" {...props}>
    <path
      stroke="#707B8F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M5.883 15.246c-2.768 0-5.133-.43-5.133-2.156 0-1.726 2.35-3.319 5.133-3.319 2.77 0 5.134 1.578 5.134 3.304 0 1.725-2.35 2.171-5.134 2.171ZM5.878 7.381a3.29 3.29 0 1 0-3.29-3.29 3.278 3.278 0 0 0 3.267 3.29h.023Z"
      clipRule="evenodd"
    />
  </svg>
);
