export const SettingsIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      stroke="#FF600D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M12 9a3 3 0 1 1 0 6 3 3 0 0 1 0-6Z"
      clipRule="evenodd"
    />
    <path
      stroke="#FF600D"
      strokeWidth={1.5}
      d="M9.175 3.497c1.21-.698 1.673-.956 2.15-1.057a3.25 3.25 0 0 1 1.35 0c.477.101.94.359 2.15 1.057l3.126 1.805c1.21.698 1.665.971 1.99 1.333.305.338.536.737.676 1.17.15.463.16.993.16 2.39v3.61c0 1.397-.01 1.927-.16 2.39-.14.433-.37.832-.675 1.17-.326.362-.781.635-1.99 1.333l-3.127 1.805c-1.21.698-1.673.956-2.15 1.057a3.25 3.25 0 0 1-1.35 0c-.477-.101-.94-.359-2.15-1.057l-3.126-1.805c-1.21-.698-1.665-.971-1.99-1.333l-.558.502.558-.502a3.25 3.25 0 0 1-.676-1.17c-.15-.463-.16-.993-.16-2.39v-3.61c0-1.397.01-1.927.16-2.39a3.25 3.25 0 0 1 .676-1.17c.325-.362.78-.635 1.99-1.333l3.126-1.805Z"
    />
  </svg>
);
