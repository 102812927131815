import Link from 'next/link';
import { Avatar, LoadingSkeleton, Text } from '@ui/components';
import { ROUTES } from '@constants/routes.constants';

interface IMobileHeaderUser {
  username: string;
  avatar: string;
  isLoading?: boolean;
}

export function MobileHeaderUser({ avatar, username, isLoading }: IMobileHeaderUser) {
  return isLoading ? (
    <LoadingSkeleton className="h-14 w-14 rounded-full" />
  ) : (
    <div className="bg-input hover:bg-inputActive active:bg-inputActive flex items-center gap-2 overflow-hidden rounded-full p-2 font-medium">
      <Link href={ROUTES.settings}>
        <Avatar className="border-0" wrapperClassName="p-0" src={avatar} size="sm" />
      </Link>
    </div>
  );
}
