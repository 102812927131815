export const PeopleIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={19} height={16} fill="none" {...props}>
    <path
      stroke="#FF600D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M9.185 15.182c-2.7 0-5.005-.408-5.005-2.043 0-1.635 2.29-3.058 5.005-3.058 2.699 0 5.005 1.41 5.005 3.044 0 1.634-2.291 2.057-5.005 2.057ZM9.185 7.75a3.208 3.208 0 1 0-3.208-3.208A3.197 3.197 0 0 0 9.162 7.75h.023Z"
      clipRule="evenodd"
    />
    <path
      stroke="#FF600D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M14.51 6.869a2.418 2.418 0 0 0-.139-4.703M14.994 9.496c1.462 0 2.71.99 2.71 1.876 0 .52-.43 1.088-1.084 1.242M3.861 6.869a2.417 2.417 0 0 1 .137-4.703M3.376 9.496c-1.462 0-2.71.99-2.71 1.876 0 .52.43 1.088 1.085 1.242"
    />
  </svg>
);
