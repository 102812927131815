export const TicketIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      stroke={props.stroke || '#FF600D'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M13.359 3.6v2.655M13.359 17.543v2.22M13.359 14.544V9.256"
    />
    <path
      stroke={props.stroke || '#FF600D'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M21.5 14.05c-2.69 0-2.69-4.101 0-4.101 0-4.753 0-6.449-9.5-6.449S2.5 5.196 2.5 9.949c2.69 0 2.69 4.101 0 4.101 0 4.754 0 6.45 9.5 6.45s9.5-1.696 9.5-6.45Z"
      clipRule="evenodd"
    />
  </svg>
);
